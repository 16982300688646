import Siema from 'siema';

// Add a function that generates pagination to prototype
Siema.prototype.addPagination = function(cb) {
    const dotNavEl = document.createElement('div');
    dotNavEl.classList.add('dot-nav');
    this.selector.appendChild(dotNavEl);

    for (let i = 0; i < this.innerElements.length; i++) {
        const btn = document.createElement('a');
        btn.classList.add('carousel-dot');
        if (i == 0)
        {
            btn.classList.add('active');
        }
        btn.addEventListener('click', () => {
            this.goTo(i);
        });
        dotNavEl.appendChild(btn);
    }
}

class DotCarousels {
    constructor () {
        this._selector = '[data-ncomponent="dot-carousel"]';
        this._els = Array.from(document.querySelectorAll(this._selector));
        this.init();
    }

    init() {
        if (this._els.length > 0) {
            this._els.forEach((el) => this.createCarousel(el));
        }
    }

    createCarousel(el) {
        const carousel = new Siema({
            selector: this._selector,
            duration: 200,
            easing: 'ease-out',
            perPage: 1,
            startIndex: 0,
            draggable: true,
            threshold: 10,
            loop: false,
            onInit: function() {
                // Trigger pagination creator
                this.addPagination(function(value) {

                });
            },
            onChange: function() {
                const carouselDots = Array.from(el.querySelectorAll('.carousel-dot'));
                // Remove active classes then add .active to the new active el
                carouselDots.forEach((dot) => dot.classList.remove('active'));
                carouselDots[this.currentSlide].classList.add('active');
            }
        });

        
    }
}

export default new DotCarousels();
