class SearchForm {
    constructor() {
        this.SearchCheckbox = document.querySelector('[data-ntoggle="search"]');

        if (this.SearchCheckbox) {
            this.init();
        }
    }

    init() {
        this.SearchCheckbox.addEventListener('change', (e) => this.toggleSearch(e));
    }

    toggleSearch(e) {
        if (e.target.checked) {
            document.querySelector('[data-ninput="search"]').focus();
        }
    }
}

export default new SearchForm();
