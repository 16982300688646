class VerticalSlider {
    constructor() {
        this.verticalSliders = Array.from(document.querySelectorAll('[data-ncomponent="vertical-slider"]'));

        if ( this.verticalSliders.length> 0) {
            this.init();
        }
    }

    init() {
        this.verticalSliders.forEach((verticalSlider) => {
            const radios = Array.from(verticalSlider.querySelectorAll('input[name="vertical-slidenum"]'));
            const upBtn = verticalSlider.querySelector('[data-nelement="vertical-slider-up"]');
            const downBtn = verticalSlider.querySelector('[data-nelement="vertical-slider-down"]');
            const verticalSliderInner = verticalSlider.querySelector('.vertical-slider-inner');
            const verticalSliderItems = Array.from(verticalSlider.querySelectorAll('.vertical-slider-item'));
            const pct = 100 / verticalSliderItems.length;

            radios.forEach((radio) => {
                radio.addEventListener('change', () => {
                    const offset = radio.dataset.slidenum * pct;
                    console.log(`translateY(-${offset}%)`);
                    verticalSliderInner.style.transform = `translateY(-${offset}%)`;
                });
            });

            upBtn.addEventListener('click', (e) => {
                e.preventDefault();
                const currentCheckedRadio = verticalSlider.querySelector('input[name="vertical-slidenum"]:checked');
                const currentSlideNum = parseInt(currentCheckedRadio.dataset.slidenum);

                if (currentSlideNum == 0) {
                    return;
                }

                radios[currentSlideNum - 1].checked = true;

                // Have to manually fire the change event on the radio element
                const event = document.createEvent("HTMLEvents");
                event.initEvent('change', false, true);
                radios[currentSlideNum - 1].dispatchEvent(event);
            });

            downBtn.addEventListener('click', (e) => {
                e.preventDefault();
                const currentCheckedRadio = verticalSlider.querySelector('input[name="vertical-slidenum"]:checked');
                const currentSlideNum = parseInt(currentCheckedRadio.dataset.slidenum);
                
                if (currentSlideNum >= verticalSliderItems.length - 1) {
                    return;
                }

                radios[currentSlideNum + 1].checked = true;

                // Have to manually fire the change event on the radio element
                const event = document.createEvent("HTMLEvents");
                event.initEvent('change', false, true);
                radios[currentSlideNum + 1].dispatchEvent(event);
            });
        })
    }
}

export default new VerticalSlider();
