import stepRound from '../../utils/math/step-rounding';

class MooringCalc {
    constructor() {
        this.calcComponentEl = document.querySelector('[data-ncomponent="mooring-calc"]');

        if (this.calcComponentEl) {
            this.init();
        }
    }

    init() {
        this.pricePerMeterPerYear = parseFloat(this.calcComponentEl.dataset.ppm);
        this.lengthInput = this.calcComponentEl.querySelector('[data-ninput="mooring-length"]');
        this.costEl = this.calcComponentEl.querySelector('[data-nvalue="mooring-cost"]');
        this.durationSelect = this.calcComponentEl.querySelector('[data-ninput="mooring-duration"]');
        this.boatMinLength = parseFloat(this.lengthInput.min);
        this.boatMaxLength = parseFloat(this.lengthInput.max);

        this.lengthInput.addEventListener('input', () => { this.calculateCost() });

        if (this.durationSelect) {
            this.durationSelect.addEventListener('input', () => { this.calculateCost() });
        }
    }

    calculateCost() {
        if (this.lengthInput.value == 0 || isNaN(this.lengthInput.value)) {
            return;
        }

        const length = this.validateLength();
        let pricePerMeterPerYear = this.pricePerMeterPerYear;
        let cost = 0;

        if (this.durationSelect && this.durationSelect.value == 6) {
            pricePerMeterPerYear = pricePerMeterPerYear/2;
            cost = (length * pricePerMeterPerYear) * 1.1;
        }
        else {
            cost = length * pricePerMeterPerYear;
        }

        this.costEl.textContent = (cost).toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP',
          });
    }

    validateLength() {
        let length = this.lengthInput.value;

        length = stepRound(length, 0.25);
        //this.lengthInput.value = length;

        length = length >= this.boatMinLength ? length : this.boatMinLength;

        if (length > this.boatMaxLength) {
            length = this.boatMaxLength;
            this.lengthInput.value = length;
        }

        return length;
    }
}

export default new MooringCalc();
